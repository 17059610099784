var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@apollo/client';
import { Button, Form, Input } from 'antd';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { NavLink, useParams } from 'react-router-dom';
import classes from '../Auth.module.scss';
import { SIGN_UP_BY_INVITE } from '@/graphql/auth';
import useAppDispatch from '@/hooks/useAppDispatch';
import ArrowLeftIcon from '@/icons/arrow-right.svg';
import { routes } from '@/utils/constants';
import { setAccessToken } from '@/utils/localStorage';
import { getErrorMessage } from '@/utils/validateMessages';
const Registration = () => {
    var _a, _b;
    const [password, setPassword] = useState('');
    const [requirements, setRequirements] = useState({
        length: false,
        number: false,
        lowercase: false,
        uppercase: false
    });
    const intl = useIntl();
    const [form] = Form.useForm();
    const { inviteId } = useParams();
    const { authGetUser } = useAppDispatch();
    const validatePassword = (password) => {
        setPassword(password);
        setRequirements({
            length: password.length >= 6,
            number: /\d/.test(password),
            lowercase: /[a-z]/.test(password),
            uppercase: /[A-Z]/.test(password)
        });
    };
    const isPasswordValid = Object.values(requirements).every(Boolean);
    const getProgress = (_b = (_a = Object.values(requirements)) === null || _a === void 0 ? void 0 : _a.filter((require) => require)) === null || _b === void 0 ? void 0 : _b.length;
    const [signUpByInvite] = useMutation(SIGN_UP_BY_INVITE);
    const onFinish = (values) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        try {
            const { data } = yield signUpByInvite({
                variables: {
                    signUpByInviteInput: Object.assign({ invitationId: inviteId, password: values.password }, (navigator === null || navigator === void 0 ? void 0 : navigator.language) && { lang: navigator === null || navigator === void 0 ? void 0 : navigator.language })
                }
            });
            const jwtToken = (_a = data === null || data === void 0 ? void 0 : data.signUpByInvite) === null || _a === void 0 ? void 0 : _a.jwtToken;
            if (jwtToken) {
                setAccessToken(jwtToken);
                authGetUser();
            }
        }
        catch (error) {
            getErrorMessage(error, intl);
        }
    });
    return (React.createElement("div", { className: classes.auth },
        React.createElement("div", { className: classes.authHead },
            React.createElement(NavLink, { to: routes.auth.f, className: classes.BackBtn },
                React.createElement(ArrowLeftIcon, null)),
            React.createElement("div", { className: classes.title }, intl.formatMessage({ id: 'common.registration', defaultMessage: 'Регистрация' }))),
        React.createElement(Form, { autoComplete: "off", form: form, name: "register", onFinish: onFinish, scrollToFirstError: true },
            React.createElement(Form.Item, { label: intl.formatMessage({ id: 'common.createPassword', defaultMessage: 'Придумайте пароль' }), name: "password" },
                React.createElement(Input.Password, { value: password, onChange: (e) => { validatePassword(e.target.value); }, placeholder: intl.formatMessage({ id: 'common.password', defaultMessage: 'Пароль' }), autoComplete: 'new-password' })),
            React.createElement("div", { className: classes.progress }, Array(4).fill(null).map((_, index) => (React.createElement("div", { key: index, className: index < getProgress ? classes.valid : '' })))),
            React.createElement("div", { className: classes.progressDescriptions },
                React.createElement("div", { className: `${classes.progressDescriptionsItem} ${(requirements === null || requirements === void 0 ? void 0 : requirements.length) ? classes.valid : ''}` },
                    intl.formatMessage({ id: 'common.sixCharacters', defaultMessage: 'Не менше 6 символів' }),
                    !(requirements === null || requirements === void 0 ? void 0 : requirements.length) && React.createElement("span", null,
                        "(",
                        password.length,
                        "/6)")),
                React.createElement("div", { className: `${classes.progressDescriptionsItem} ${(requirements === null || requirements === void 0 ? void 0 : requirements.number) ? classes.valid : ''}` }, intl.formatMessage({ id: 'common.numberCharacters', defaultMessage: 'Наявність хоча б однієї цифри' })),
                React.createElement("div", { className: `${classes.progressDescriptionsItem} ${(requirements === null || requirements === void 0 ? void 0 : requirements.lowercase) ? classes.valid : ''}` }, intl.formatMessage({ id: 'common.smallCharacters', defaultMessage: 'Наявність літери малого регістру' })),
                React.createElement("div", { className: `${classes.progressDescriptionsItem} ${(requirements === null || requirements === void 0 ? void 0 : requirements.uppercase) ? classes.valid : ''}` }, intl.formatMessage({ id: 'common.bigCharacters', defaultMessage: 'Наявність літери великого регістру' }))),
            React.createElement(Button, { disabled: !isPasswordValid, type: "primary", htmlType: "submit", className: 'full high' }, intl.formatMessage({ id: 'common.register', defaultMessage: 'Зарегистрироваться' })),
            React.createElement("div", { className: classes.row },
                React.createElement("div", { className: classes.titleLink }, intl.formatMessage({ id: 'common.alreadyRegistered', defaultMessage: 'Уже зарегистрирован?' })),
                React.createElement(NavLink, { to: routes.login.f, className: classes.link }, intl.formatMessage({ id: 'common.signIn', defaultMessage: 'Увійти' }))))));
};
export default Registration;
